<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name; delete serverErrors.slug}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name||serverErrors.slug"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.phone = v; delete serverErrors.phone; delete serverErrors.slug}" 
                    :valueInput="values.phone" 
                    :rule="[...rules.phone]" 
                    label="Teléfono"
                    :errorMessages="serverErrors.phone||serverErrors.slug"
                    data-cy="phone-input"
                    counter="10"
                    refIn="phoneinput"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.email = v; delete serverErrors.email; delete serverErrors.slug}" 
                    :valueInput="values.email" 
                    :rule="[...rules.required,...rules.email]" 
                    label="Correo Electrónico"
                    :errorMessages="serverErrors.email||serverErrors.slug"
                    data-cy="email-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha de la salida"
                    :valueInput="values.admission_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.admission_date = v; delete serverErrors['admission_date']}"
                    :errorMessages="serverErrors['admission_date']"
                    data-cy="admission_date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                phone: [
                    v => !!v || 'El campo ES REQUERIDO',
                    v => /^[0-9]*$/.test(v) || 'El campo SOLO DEBE CONTENER NÚMEROS',
                    v => v.length === 10 || 'El campo DEBE CONTENER 10 DIGITOS'
                ],
                email: [
                    (v) => {
                        // if(v == "")
                        //     return true;
                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
            },
            

          
        }
    },
   
    mounted(){
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>